<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="red">mdi-alert-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">アラート確認</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  :counter="512"
                  label="アラート確認メモ"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            
            @click="onClickCreate"
          >
            アラート確認
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

// 栽培情報情報登録ダイアログ
export default {
  name: "AlertConfirmDialog",
  components: {
  },
  props: {
    plantInfo: Object()
  },
  mounted() {
  },
  data() {
    return {
      description: '',
      valid: false,
      dialog: false
    }
  },
  computed: {
  },
  methods: {
    validate () {
      return this.$refs.form.validate();
    },
    resetValidation () {
      return this.$refs.form.resetValidation();
    },
    formReset () {
      return this.$refs.form.reset();
    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        axios.post(`/api/alert_memo/`,{
          'plant_info': String(this.plantInfo.id),
          'description': this.description
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          this.$emit('reload');
          Swal.fire({
            icon: 'info',
            title: 'アラート確認',
            text: 'アラートが確認されました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          });
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
