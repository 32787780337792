<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="buttonColor"
        
        v-bind="attrs"
        v-on="on"
        v-if="!isUpdate"
      >
        {{'栽培年度新規追加'}}
      </v-btn>
      <v-icon v-if="isUpdate" @click.stop="dialog = true">mdi-application-edit</v-icon>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">{{isUpdate ? '栽培年度の更新' : '栽培年度の登録'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="date"
                  :counter="4"
                  :rules="dateRules"
                  label="年度"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            v-if="!isUpdate"
            color="primary"
            
            @click="onClickCreate"
          >
            新規追加
          </v-btn>
          <v-btn
          v-if="isUpdate"
            color="primary"
            
            @click="onClickUpdate"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import AuthUtils from '@/utils/AuthUtils.js'

// エリア登録ダイアログ
export default {
  name: "AreaGroupDialog",
  components: {
  },
  props: {
    plantMaster: Object(),
    group_year: Object()
  },
  mounted() {
  },
  data() {
    return {
      date: '',
      dateRules: [
        v => !!v || '年度 が 必要です',
        v => (new RegExp(/^[0-9]*$/).test(v)) || '年度は数字で入力してください',
        v => (v && String(v).length <= 4) || '年度 は 4桁以内で入力してください',
      ],
      valid: false,
      dialog: false
    }
  },
  computed: {
    isUpdate(){
      return this.group_year != undefined;
    },
    buttonColor(){
      return this.isUpdate ? "" : "primary"
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.group_year) {
          this.date = new Date(this.group_year.date).toISOString().substr(0, 4);
          this.name = this.group_year.name;
        }
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        const date = new Date(this.date).toISOString();
        axios.post('/api/group_years/', {
          'date': date,
          'plant_master_id': this.plantMaster,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '栽培年度の登録',
            text: '栽培年度の登録が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickUpdate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        const date = new Date(this.date).toISOString();
        axios.put(`/api/group_years/${this.group_year.id}/`, {
          'date': date,
          'plant_master_id': this.plantMaster,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '栽培年度の更新',
            text: '栽培年度の更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
