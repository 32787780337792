<template>
  <v-container fluid >
    <v-layout
        row
        fill-height
        justify-center
        align-center
        v-if="loading"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </v-layout>
    <v-row dense class="mx-auto">
      <v-col
        :cols="chartCols"
        v-bind:class="{ hide: selectYearGroupId != '' }">
        <v-checkbox v-model="isFinish" label="過去データを表示する"/>
        <v-select
          v-model="finishYear"
          label="過去データの年度"
          :items="finishYearList"
          v-if="isFinish"/>
        <v-tabs
          v-model="tab"
          align-with-title
          show-arrows
        >
          <v-tabs-slider color="blue"></v-tabs-slider>

            <v-tab
              v-for="cropItem in cropItems"
              :key="cropItem.value"
              @click.stop = "onClickTab(cropItem)"
            >
              <v-badge
                    :content="getCropAlert(cropItem)"
                    :value="getCropAlert(cropItem)"
                    color="red"
                    right
                  >
                <span>
                  {{ cropItem.text }}
                </span>
              </v-badge>
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-card class="searchArea overflow-y-auto overflow-x-hidden" v-bind:style="{height:yearGroupHeight}">
          <v-row dense>
            <v-col
                :cols="12"
                >
              <v-list dense>
                <template v-for="(plant_master) in displayPlantMasterList">
                  <v-list-group
                    :key="plant_master.id"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        {{ plant_master.name }}
                      </v-list-item-content>
                      <v-divider></v-divider>
                      <v-badge
                        :content="getPlantMasterAlert(plant_master)"
                        :value="getPlantMasterAlert(plant_master)"
                        inline
                        right
                        color="red"
                        offset-y="24"
                        overlap
                      ></v-badge>
                    </template>

                    <template v-for="(group_year) in getDisplayYearGroupList(plant_master)">
                      <v-list-item
                        :key="group_year.id"
                        @click.stop="onSelectYearGroup(group_year.id)"
                        link
                        dense
                      >
                        <v-list-item-title >
                          {{new Date(group_year.date).toISOString().substr(0, 4)}}
                        </v-list-item-title>

                        <v-divider></v-divider>
                        <v-badge
                          :content="getYearGroupAlert(group_year)"
                          :value="getYearGroupAlert(group_year)"
                          inline
                          right
                          color="red"
                          offset-y="24"
                          overlap
                        ></v-badge>
                      </v-list-item>
                    </template>
                  </v-list-group>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
          :cols="chartCols"
          v-bind:class="{ hide: selectYearGroupId=='' }"
          >
        <v-card>
          <v-card-text dense>
            <v-container>
              <v-row dense>
                <v-col
                  cols="12"
                >
                  <v-btn class="text-h6" @click="onSelectYearGroup('');">  {{getCurrentCropName}} > {{ getCurrentAreaName }} </v-btn>
                  <v-card-subtitle v-if="!isBasic">平年値のご利用には拡張プランが必要です。</v-card-subtitle>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text dense>
            <v-container>
              <LineChart
                :propData="chartData"
                :labels="chartLabels"
                :height="220"
                @onChartClick="onChartClick"
                ></LineChart>
            </v-container>
            <small>Contains modified Copernicus Sentinel data [2017-2024]</small>
          </v-card-text>
          <v-divider></v-divider>
          <v-card class="searchArea overflow-y-auto overflow-x-hidden" v-bind:style="{height:plantInfoHeight}">
            <v-row dense>
              <v-col
                  v-for="plantInfo in plantInfoList"
                  :cols="12"
                  :key="plantInfo.id"
                  @click.stop="onSelecPlantInfo(plantInfo)"
                  class="plantInfo"
                  v-bind:class="{ selected: selectPlantInfoId == plantInfo.id }"
                  >
                <v-card
                  color="#ffffff"
                >
                  <v-badge
                      :content="getPlantAlert(plantInfo)"
                      :value="getPlantAlert(plantInfo)"
                      color="red"
                      right
                      offset-x="24"
                      offset-y="24"
                    >
                    <v-card-title class="text-h5" v-bind:class="{stop: plantInfo.is_stop}">
                        {{ plantInfo.field.name }}
                    </v-card-title>
                  </v-badge>

                  <v-card-subtitle>
                    <span v-bind:class="{stop: plantInfo.is_stop}">
                      定植日 : {{ new Date(plantInfo.plant_date).toISOString().substr(0, 10) }}
                      <br> {{ plantInfo.field.address }}
                      <br> {{ plantInfo.field.description }}
                    </span>
                    <br>
                    <span class="red--text" v-if="plantInfo.is_stop">*中断済み</span>
                    <span v-if="!plantInfo.is_stop && plantInfo.finish_date">*終了済み</span>
                    <br>
                    <span v-if="plantInfo.finish_date">終了日時 : {{new Date(plantInfo.finish_date).toISOString().substr(0, 10)}}</span>
                    <br>
                    <span v-if="plantInfo.finish_date && plantInfo.stop_memo">メモ：{{plantInfo.stop_memo}}</span>
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-divider></v-divider>
                    <AlertConfirmDialog v-if="plantInfo.alert_status != 0" :plantInfo="plantInfo" @reload="onConfirmAlert"></AlertConfirmDialog>
                    <AlertConfirmListDialog :plantInfo="plantInfo"></AlertConfirmListDialog>
                    <PlantInfoStopDialog v-if="!plantInfo.finish_date" :plantInfo="plantInfo" @reload="onConfirmAlert"></PlantInfoStopDialog>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-col>
      <v-col
          :cols="cesiumCols"
          >
        <div class="viewer" v-bind:style="{height:viewerHeight}">
          <vc-viewer @ready="ready" :infoBox="false" :selectionIndicator="false">
            <vc-layer-imagery>
              <vc-provider-imagery-arcgis-mapserver :url="url" />
            </vc-layer-imagery>

            <vc-entity v-for="polygon in polygons" :key="polygon.id" @click="polygonClick(polygon)">
              <vc-graphics-polygon
                v-if="isPolygonView"
                :hierarchy="convertHierarchy(polygon)"
                :material="getMaterial(polygon)"
                :extrudedHeight="0.0"
                :closeTop="false"
                :closeBottom="false"
                :ref="polygonRef(polygon.id)"
                :outline="true"
                :outlineColor="getOutLineMaterial(polygon)"
                :outlineWidth="5"
              ></vc-graphics-polygon>
            </vc-entity>
            <vc-primitive v-if="!isPolygonView" :appearance="appearance" :geometry-instances="geometryInstances"></vc-primitive>
            <vc-primitive v-if="!isPolygonView" :appearance="mapAppearance" :geometry-instances="mapGeometryInstances"></vc-primitive>
            <vc-primitive v-if="!isPolygonView" :appearance="attentionAppearance" :geometry-instances="attentionGeometryInstances"></vc-primitive>
          </vc-viewer>
        </div>
      </v-col>
    </v-row>
    <SetFarmersInfoDialog
      :dialog="firstDialog"
      @close-dialog="firstDialog = false"
    />
  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';
import LineChart from '../comps/LineChart';
import AlertConfirmDialog from '../comps/AlertConfirmDialog';
import AlertConfirmListDialog from '../comps/AlertConfirmListDialog';
import PlantInfoStopDialog from '../comps/PlantInfoStopDialog';
import SetFarmersInfoDialog from '../comps/SetFarmersInfoDialog';
import AuthUtils from '@/utils/AuthUtils.js';

export default {
  name: "Top",
  components: {
    LineChart,
    AlertConfirmDialog,
    AlertConfirmListDialog,
    PlantInfoStopDialog,
    SetFarmersInfoDialog
  },
  mounted() {
    this.reload();
    this.windowSize.width = window.innerWidth;
    this.windowSize.height = window.innerHeight;
  },
  data() {
    return {
      Cesium: {},
      viewer: {},
      camera: {},
      appearance: null,
      geometryInstances: null,
      mapAppearance: null,
      mapGeometryInstances: null,
      attentionAppearance: null,
      attentionGeometryInstances: null,
      mapAppearanceSen1: null,
      mapAppearanceSen2: null,
      attentionAppearanceSen1: null,
      attentionAppearanceSen2: null,
      loading: false,
      tab: "",
      crop: "",
      cropItems: [],
      groupYears: [],
      plantMasters: [],
      selectYearGroupId: "",
      plantInfoList: [],
      selectPlantInfoId: "",
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
      chartCols: 4,
      polygons: [],
      drawData: {},
      material: [0.1, 1, 0.2, 0.5],
      alertMaterial: [1, 0.1, 0.2, 0.5],
      outLineMaterial: [0.1, 1, 0.2, 1],
      outLineAlertMaterial: [1, 0.1, 0.2, 1],
      ndviData: [],
      ndviAvg: {},
      ndviAreaGroupAvg: {},
      isFinish: false,
      finishYear: "",
      windowSize: {
        width:0,
        height:0
      },
      cahrtOption: {
      },
      firstDialog: '',
    }
  },
  methods: {
    ready(cesiumInstance) {
      const { Cesium, viewer } = cesiumInstance;
      this.Cesium = Cesium;
      this.viewer = viewer;
      viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

      this.mapAppearanceSen2  = new this.Cesium.MaterialAppearance({
        material: new Cesium.Material({
          fabric: {
            type: 'Image',
            uniforms: {
              image: './static/ndvi_map.png'
            }
          }
        })
      });

      this.mapAppearanceSen1  = new this.Cesium.MaterialAppearance({
        material: new Cesium.Material({
          fabric: {
            type: 'Image',
            uniforms: {
              image: './static/ndvi_map_sen1.png'
            }
          }
        })
      });

      this.attentionAppearanceSen2  = new this.Cesium.MaterialAppearance({
        material: new Cesium.Material({
          fabric: {
            type: 'Image',
            uniforms: {
              image: './static/attention_sen2.png'
            }
          }
        })
      });

      this.attentionAppearanceSen1  = new this.Cesium.MaterialAppearance({
        material: new Cesium.Material({
          fabric: {
            type: 'Image',
            uniforms: {
              image: './static/attention_sen1.png'
            }
          }
        })
      });
    },
    reload() {
      this.checkLoggedIn();
      axios.get('/api/crops/').then((res) => {
        this.cropItems = res.data.map((d)=> {
          return {'text':d.name, 'value':d.id}
        });
        this.crop = this.cropItems[0].value;
      });
      const current_user = AuthUtils.currentUser(this.$session);
      this.firstDialog = !current_user.is_info_checked;
      this.groupYears = [];
      axios.get(`/api/plant_master/?user_id=${current_user.id}`).then((res) => {
        this.plantMasters = res.data.filter((plantMaster) => {
          if (!plantMaster.group_year_list) return false;
          if (plantMaster.group_year_list.length < 1) return false;

          const gropuYears = plantMaster.group_year_list.filter(groupYear => groupYear.plant_info_list.length > 0);
          if (gropuYears.length < 1){
            return false;
          }
          return true;
        });
        this.plantMasters.forEach(plantMaster=>{
          if (plantMaster.group_year_list.length < 1) return;
          this.groupYears = plantMaster.group_year_list.concat(this.groupYears);
        });
      });
    },
    getPlantInfo(fieldId) {
      return this.plantInfoList.filter(plantInfo => plantInfo.field.id == fieldId)[0];
    },
    getPlantMasterFromGroupYear(groupYear){
      return this.plantMasters.filter(plantMaster => plantMaster.id == groupYear.plant_master)[0];
    },
    polygonClick(polygon) {
      const plantInfo = this.getPlantInfo(polygon.field.id);
      this.onSelecPlantInfo(plantInfo);
    },
    polygonRef(polygon_id) {
      return `polygon_${polygon_id}`;
    },
    convertHierarchy(polygon) {
      return polygon.poslist.map((pos) => {
        return { "lng": pos.lng, "lat": pos.lat, height: 0 }
      });
    },
    onSelectYearGroup(selectYearGroupId){
      this.selectYearGroupId = selectYearGroupId;
      if (this.selectYearGroupId) {
        const group_year = this.groupYears.filter(group_year => group_year.id == this.selectYearGroupId)[0];
        // ndvi_year_avg 平年値
        this.ndviData = [];
        Promise.all([
          axios.get(`/api/ndvi_group_year_avg/?plant_master_id=${group_year.plant_master}&group_year_id=${group_year.id}`).then((res) => {
            if (res.data[0]) {
              this.ndviAreaGroupAvg = res.data[0];
            }
          }),
          axios.get(`/api/ndvi_avg/?plant_master_id=${group_year.plant_master}&group_year_id=${group_year.id}`).then((res) => {
            if (res.data[0]) {
              this.ndviAvg = res.data[0];
            }
          }),
          axios.get(`/api/polygon_detail/?group_year_id=${selectYearGroupId}`).then((res) => {
            this.polygons = res.data;
            this.polygons.forEach((polygon) => {
              polygon.poslist.sort((a, b) => a.sequence - b.sequence);
            })
          }).then(()=>{
            axios.get(`/api/plant_info/?group_year_id=${selectYearGroupId}`).then((res) => {
              this.plantInfoList = res.data.filter(plantInfo => this.getPolygon(plantInfo.field.id).length > 0 );
            })
          })
        ]).then(()=>{
          const polygon = this.polygons.filter((polygon)=>{
            return polygon.field.id == group_year.plant_info_list[0].field.id;
          });
          if (polygon.length <= 0) return;

          const lng = polygon[0].poslist[0].lng -0.0181700;
          const lat = polygon[0].poslist[0].lat + 0.014866;
          this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(lng, lat,7000.0)})
        })
      } else {
        this.selectPlantInfoId = '';
        // カメラ引き
        this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(139.146028, 36.221613, 20000000.0)})
      }
    },
    onSelecPlantInfo(plantInfo){
      const plantInfoId = plantInfo.id;
      this.selectPlantInfoId = plantInfoId == this.selectPlantInfoId ? '' : plantInfoId;
      if (this.selectPlantInfoId) {
        const plantInfo = this.plantInfoList.filter(plantInfo => plantInfo.id == this.selectPlantInfoId)[0];
        axios.get(`/api/ndvi/?plant_info_id=${plantInfoId}&is_valid=True`).then((res) => {
          this.ndviData = res.data;
          this.ndviData.sort((a, b) => a.elapse_date - b.elapse_date)
        }).then(()=>{
          const result = this.getPolygon(plantInfo.field.id);
          const pos = result[0].poslist[0]
          this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, 1000.0)})
        });
      } else {
        this.geometryInstances = null;
        this.appearance = null;
        this.onSelectYearGroup(plantInfo.group_year.id);
      }
    },
    getPolygon(field_id) {
      return this.polygons.filter((polygon)=>{
        return polygon.field && polygon.field.id == field_id;
      });
    },
    /// TODO to mixin
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    getMaterial(polygon){
      const plantInfo = this.getPlantInfo(polygon.field.id);
      if (!plantInfo) return this.material;
      return plantInfo.alert_status != 0 ? this.alertMaterial : this.material;
    },
    getOutLineMaterial(polygon){
      const plantInfo = this.getPlantInfo(polygon.field.id);
      if (!plantInfo) return this.outLineMaterial;
      return plantInfo.alert_status != 0 ? this.outLineAlertMaterial : this.outLineMaterial;
    },
    getPlantAlert(plantInfo) {
      return plantInfo.alert_status != 0 ? " " : "";
    },
    getCropAlert(cropItem) {
      const alertGroupYears = this.groupYears.filter(group_year => (this.getPlantMasterFromGroupYear(group_year).crop.id == cropItem.value && group_year.has_alert));
      return alertGroupYears.length > 0 ? " " : "";
    },
    getPlantMasterAlert(plant_master) {
      const alertGroupYears = plant_master.group_year_list.filter(group_year => group_year.has_alert);
      return alertGroupYears.length > 0 ? " " : "";
    },
    getYearGroupAlert(group_year) {
      return group_year.has_alert ? " " : "";
    },
    getPointColor(ndvi) {
      if (ndvi.status == -1) return "#FF00FF";
      if (ndvi.status == 1) return "#00FFFF";
      return "#00FF00"
    },
    getDisplayYearGroupList(plant_master) {
      if (this.isFinish) {
        if (!this.finishYear) {
          return plant_master.group_year_list;
        }
        return plant_master.group_year_list.filter(groupYear => {
          const yearStr = new Date(groupYear.date).toISOString().substr(0, 4);
          return yearStr == this.finishYear;
        });
      }
      return plant_master.group_year_list.filter((groupYear) => !groupYear.is_finish);
    },
    onClickTab(cropItem) {
      this.crop = cropItem.value;
    },
    onConfirmAlert() {
      this.reload();
      axios.get(`/api/plant_info/?group_year_id=${this.selectYearGroupId}`).then((res) => {
        this.plantInfoList = res.data.filter(plantInfo => this.getPolygon(plantInfo.field.id).length > 0 );
      });
    },
    calcRectPos (poslist) {
      const west = poslist.reduce(function(posA, posB) {
        return posA.lng < posB.lng ? posA: posB;
      });
      const south = poslist.reduce(function(posA, posB) {
        return posA.lat < posB.lat ? posA: posB;
      });
      const east = poslist.reduce(function(posA, posB) {
        return posA.lng > posB.lng ? posA: posB;
      });
      const north = poslist.reduce(function(posA, posB) {
        return posA.lat > posB.lat ? posA: posB;
      });

      return [west.lng, south.lat, east.lng, north.lat]
    },
    onChartClick (event, tooltipItems) {
      if (!tooltipItems[0]) {
        this.geometryInstances = null;
        this.appearance = null;
        return;
      }
      const chart = tooltipItems[0]._chart;
      const chartIndex = chart.getElementAtEvent(event);
      if (chartIndex.length == 0 || chartIndex[0]._datasetIndex != 2) {
        this.geometryInstances = null;
        this.appearance = null;
        return;
      }
      const position = chartIndex[0]._index;
      const current_user = AuthUtils.currentUser(this.$session);
      const plantInfo = this.plantInfoList.filter(plantInfo => plantInfo.id == this.selectPlantInfoId)[0];
      const target_date = new Date(plantInfo.plant_date);
      target_date.setDate(target_date.getDate() + position);
      const imagePath = `./static/sentinel_ndvi/user_${current_user.id}/field_${plantInfo.field.id}/plant_${plantInfo.id}/${target_date.toISOString().substr(0, 10)}.png`
      const polygon = this.getPolygon(plantInfo.field.id)[0];
      const pos = this.calcRectPos(polygon.poslist);

      this.geometryInstances = new this.Cesium.GeometryInstance({
        geometry: new this.Cesium.RectangleGeometry({
          rectangle: this.Cesium.Rectangle.fromDegrees(
            pos[0],
            pos[1],
            pos[2],
            pos[3]
          )
        }),
        height: 1000
      });

      this.mapGeometryInstances = new this.Cesium.GeometryInstance({
        geometry: new this.Cesium.RectangleGeometry({
          rectangle: this.Cesium.Rectangle.fromDegrees(
            pos[2]+0.0001,
            pos[1],
            pos[2]+0.0004,
            pos[1]+0.0006
          )
        }),
        height: 1000
      });

      this.attentionGeometryInstances = new this.Cesium.GeometryInstance({
        geometry: new this.Cesium.RectangleGeometry({
          rectangle: this.Cesium.Rectangle.fromDegrees(
            pos[0],
            pos[3]+0.0001,
            pos[0]+0.0012,
            pos[3]+0.00025
          )
        }),
        height: 1000
      });

      this.appearance  = new this.Cesium.MaterialAppearance({
        material: new this.Cesium.Material({
          fabric: {
            type: 'Image',
            uniforms: {
              image: imagePath
            }
          }
        })
      });

      const ndvi = this.ndviData.filter((ndvi)=>{
        return ndvi.elapse_date == position;
      })[0];
      if (ndvi.sen2_value) {
        this.mapAppearance = this.mapAppearanceSen2;
        this.attentionAppearance = this.attentionAppearanceSen2;
      } else {
        this.mapAppearance = this.mapAppearanceSen1;
        this.attentionAppearance = this.attentionAppearanceSen1;
      }
    }
  },
  computed : {
    isPolygonView(){
      return !this.appearance;
    },
    isBasic() {
      return AuthUtils.isBasic(this.$session);
    },
    viewerHeight() {
      return `${this.windowSize.height - 100}px`
    },
    plantInfoHeight() {
      // TODO height
      return `${this.windowSize.height - 352 - 120}px`
    },
    yearGroupHeight() {
      // TODO height
      const isFinisHeight = this.isFinish ? 120 : 60;
      return `${this.windowSize.height - 52 - 120 - isFinisHeight}px`
    },
    displayPlantMasterList() {
      if (!this.crop) {
        return this.plantMasters;
      }
      const plantMasterList = this.plantMasters.filter(plant_master => plant_master.crop.id == this.crop)
      if (this.isFinish){
        return plantMasterList;
      }
      return plantMasterList.filter(plant_master => this.getDisplayYearGroupList(plant_master).length > 0);

    },
    finishYearList () {
      if (this.groupYears.length < 1) {
        return [];
      }
      if (!this.crop) {
        return this.groupYears;
      }
      const groupYearList = this.groupYears.filter(groupYear => this.getPlantMasterFromGroupYear(groupYear).crop.id == this.crop)
      .map((groupYear) => {
        const yearStr = new Date(groupYear.date).toISOString().substr(0, 4)
        return {
          'text': yearStr,
          'value': yearStr
        }});

      groupYearList.unshift({'text':'過去データの選択','value': ''});
      return groupYearList;
    },
    getCurrentCropName() {
      if (!this.crop) {
        return ''
      }
      return this.cropItems.filter((crop) => (crop.value == this.crop))[0].text;
    },
    getCurrentAreaName() {
      if (this.groupYears.length < 1) {
        return [];
      }

      if (!this.selectYearGroupId) {
        return ''
      }
      const plant_master = this.getPlantMasterFromGroupYear(this.groupYears.filter(group_year => group_year.id == this.selectYearGroupId)[0]);
      return plant_master.name;
    },
    chartNdviAvgData() {
      let avgData = [];
      if (Object.keys(this.ndviAvg).length > 0 && this.ndviAvg.ndvi_avg_value_list.length > 0) {
        avgData = this.ndviAvg.ndvi_avg_value_list.map((ndvi_avg_value)=>ndvi_avg_value.value);
      }
      return avgData
    },
    cesiumCols() {
      return 12 - this.chartCols;
    },
    chartLabels(){
      let elapse_date = [];
      if (!this.selectPlantInfoId) {
        let NAelapse_date = [];
        let NYGAelapse_date = [];
        if (Object.keys(this.ndviAvg).length > 0 && this.ndviAvg.ndvi_avg_value_list.length > 0) {
          NAelapse_date = this.ndviAvg.ndvi_avg_value_list.map((ndvi_avg_value)=>ndvi_avg_value.elapse_date)
        }
        if (Object.keys(this.ndviAreaGroupAvg).length > 0 && this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.length > 0) {
          NYGAelapse_date = this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.map((ndvi_avg_value)=>ndvi_avg_value.elapse_date)
        }

        elapse_date = NAelapse_date.lengh > NYGAelapse_date ? NAelapse_date : NYGAelapse_date;
      } else {
        const plantInfo = this.plantInfoList.filter(plantInfo => plantInfo.id == this.selectPlantInfoId)[0];
        const delta_date = new Date(plantInfo.harvest_date) - new Date(plantInfo.plant_date);
        elapse_date = [...Array(delta_date / 86400000).keys()]
        elapse_date = elapse_date.map(elapse_date => {
          const e_date = new Date(plantInfo.plant_date);
          e_date.setDate(e_date.getDate() + elapse_date);
          return e_date.toISOString().substr(5,5).replaceAll("-","/");
        });
      }
      return elapse_date;
    },
    chartData() {
      const chartData = [];
      const ndviData = [];
      const ndviColor = [];
      let avgData = [];
      let avgAreaGroupData = [];
      let alertNdvi = null;

      if (this.selectPlantInfoId) {
        const plantInfo = this.plantInfoList.filter(plantInfo => plantInfo.id == this.selectPlantInfoId)[0];
        const delta_date = new Date(plantInfo.harvest_date) - new Date(plantInfo.plant_date);
        const terms = delta_date / 86400000;
        for (let elapse_date = 0; elapse_date < terms; elapse_date++) {
          // ndvi
          const ndvi = this.ndviData.filter((ndvi)=>{
            return ndvi.elapse_date == elapse_date;
          })
          if (ndvi.length > 0) {
            ndviData.push(ndvi[0].value);
            ndviColor.push(this.getPointColor(ndvi[0]));
            if (ndvi[0].alert_status !=0) {
              alertNdvi = ndvi[0];
            } else {
              alertNdvi = null;
            }
          } else {
            ndviData.push(null);
            ndviColor.push(null);
          }

          // 平年値
          const avg = this.ndviAvg.ndvi_avg_value_list.filter((avg)=>{
            return avg.elapse_date == elapse_date;
          })

          if (avg.length > 0) {
            avgData.push(avg[0].value);
          } else {
            avgData.push(null);
          }

          // 平均値
          const group_year_avg = this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.filter((group_year_avg)=>{
            return group_year_avg.elapse_date == elapse_date;
          })

          if (group_year_avg.length > 0) {
            avgAreaGroupData.push(group_year_avg[0].value);
          } else {
            avgAreaGroupData.push(null);
          }

        }
      } else {
        if (this.ndviAvg.ndvi_avg_value_list && this.ndviAvg.ndvi_avg_value_list.length > 0) {
          avgData = this.ndviAvg.ndvi_avg_value_list.map((ndvi_avg_value)=>ndvi_avg_value.value);
        } else {
          avgData.push(null);
        }

        if (this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list && this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.length > 0) {
          avgAreaGroupData = this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.map((nagav)=>nagav.value);
        } else {
          avgAreaGroupData.push(null);
        }
      }

      if (alertNdvi) {
        ndviColor[alertNdvi.elapse_date] = "#FF0000";
      }

      chartData.push({
        label: "平年値",
        data: AuthUtils.isBasic(this.$session) ? avgData : [],
        borderColor: '#FF8800',
        pointBackgroundColor: '#FF8800',
        showLine: true,
        lineTension: 0.0,
        spanGaps: true,
        datalabels: {
          display: false
        },
        order: 3,
      });

      chartData.push({
        label: "平均値",
        data: avgAreaGroupData,
        borderColor: '#00AA88',
        pointBackgroundColor: '#00AA88',
        showLine: true,
        lineTension: 0.0,
        spanGaps: true,
        datalabels: {
            display: false
        },
        order: 2,
      });

      if (this.selectPlantInfoId) {
        chartData.push({
          label: "NDVI",
          data: ndviData,
          borderColor: '#00FF00',
          pointBackgroundColor: ndviColor,
          pointBorderColor: ndviColor,
          showLine: false,
          datalabels: {
            display: false
          },
          order: 1,
        });
      }
      return chartData;
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #ffffff;
}
.hide {
  display:none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
.stop {
  text-decoration: line-through;
}

</style>
