<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-close-octagon-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text>
          <v-card-title>
            <span class="text-h5">栽培終了</span>
          </v-card-title>
          <v-card-subtitle>
            <span>この栽培を終わりますか？※一度停止すると、再び再開することはできません</span>
          </v-card-subtitle>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select 
                  v-model="finishReason" 
                  label="停止理由"
                  required
                  :items="finishReasonItems"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-menu
                  v-model="finish_date_menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="finishDate"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      label="終了日"
                      :rules="[v => !!v || '終了日が必要です']"
                      @click:clear="plantDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="finishDate"
                    @change="finish_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="stop_memo"
                  :counter="512"
                  label="メモ"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            @click="onClickCreate"
          >
            登録
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

// 栽培終了ダイアログ
export default {
  name: "PlantInfoStopDialog",
  components: {
  },
  props: {
    plantInfo: Object()
  },
  mounted() {
  },
  data() {
    return {
      stop_memo: '',
      finish_date_menu: false,
      finishDate: '',
      valid: false,
      dialog: false,
      finishReason: 0,
      finishReasonItems: [
        {'text': '栽培完了', 'value': 0},
        {'text': '栽培を中断', 'value': 1}
      ]
    }
  },
  computed: {
  },
  methods: {
    validate () {
      return this.$refs.form.validate();
    },
    resetValidation () {
      return this.$refs.form.resetValidation();
    },
    formReset () {
      return this.$refs.form.reset();
    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        const finishDate = new Date(this.finishDate);
        axios.patch(`/api/plant_info/${this.plantInfo.id}/`, {
            'is_stop': this.finishReason,
            'finish_date': finishDate.toISOString(),
            'stop_memo': this.stop_memo
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          this.$emit('reload');
          Swal.fire({
            icon: 'info',
            title: '栽培終了',
            text: '栽培終了を登録しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          });
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          });
        });
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
