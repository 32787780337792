<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        text
        small
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @click="reload"
      >
        <v-icon class="reverse">mdi-triangle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-tabs
          v-model="tab"
          align-with-title
          show-arrows
        >
          <v-tabs-slider color="blue"></v-tabs-slider>
          <v-tab
            v-for="cropItem in cropItems"
            :key="cropItem.value"
            @click.stop="onClickTab(cropItem)"
          >
            {{ cropItem.text }}
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>栽培グループ</th>
                <th>公開</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="plantMaster in visiblePlantMasters"
                :key=plantMaster.id
              >
                <td>{{ plantMaster.name }}</td>
                <td>
                  <v-checkbox
                    v-model="plantMaster.isCooperated"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          
          @click="dialog = false"
        >
          閉じる
        </v-btn>
        <v-btn
          color="primary"
          
          @click="onClickReleaseButton"
        >
          公開
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import AuthUtils from '@/utils/AuthUtils.js';
import router from "../../router";

export default {
  name: 'CooperationPlantMastersDialog',
  mounted() {
    this.reload()
  },
  props: {
    coop_id: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      tab: '',
      dialog: false,
      plantMasters: [],
      visiblePlantMasters: [],
      crop: {},
      cropItems: [],
    }
  },
  methods: {
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    async reload() {
      this.checkLoggedIn()
      const current_user = AuthUtils.currentUser(this.$session);

      // 栽培グループの取得
      await axios.get(`/api/plant_master/?user_id=${current_user.id}`).then((res) => {
        this.plantMasters = res.data.map((val) => {
          val.isCooperated = false
          return val
        })
      })
      // 連携している栽培グループの取得
      axios.get(`/api/coop_plant_masters/?coop_id=${this.coop_id}`).then(res => {
        res.data.forEach((obj) => {
          this.plantMasters.map(val => {
            if(obj.plant_master_id === val.id) {
              val.isCooperated = true
            }
            return val
          })
        })
      })

      // 全作物種類の取得
      axios.get('/api/crops/').then((res) => {
        this.cropItems = res.data.map((d)=> {
          return {'text':d.name, 'value':d.id}
        });
        this.crop = this.cropItems[0];
      });
        this.tab = 0
    },
    onClickTab(cropItem) {
      this.crop = cropItem;
    },
    onClickReleaseButton() {
      let cooperationPlantMasterIdList = []
      this.plantMasters.forEach(obj => {
        if(obj.isCooperated) {
          cooperationPlantMasterIdList.push(obj.id)
        }
      })
      axios.post('/api/coop_plant_masters/', {
        'coop_id': this.coop_id,
        'coop_plant_masters': cooperationPlantMasterIdList
      }).then(() => {
        this.reload()
        this.dialog = false
      })
    },
  },
  watch: {
    crop: {
      immediate: true,
      handler(newVal) {
        this.visiblePlantMasters = this.plantMasters.filter((val) => {
          return val.crop.id === newVal.value
        })
      }
    }
  }
}
</script>
<style scoped>
.reverse {
  transform: scale(1, -1);
}
</style>
