<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="buttonColor"
        v-bind="attrs"
        v-on="on"
      >
        作物の追加
      </v-btn>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">作物の追加</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :counter="50"
                  :rules="nameRules"
                  label="作物名"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nameKana"
                  :counter="50"
                  :rules="nameKanaRules"
                  label="フリガナ"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <template v-if="showInfo">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="shippingPeriod"
                    label="出荷時期"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="shipment"
                    label="出荷量"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="scale"
                    label="栽培規模"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="price"
                    label="参考価格"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="showInfo = !showInfo">
            詳細入力
            <v-icon v-if="!showInfo">mdi-plus</v-icon>
            <v-icon v-if="showInfo">mdi-minus</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            @click="onClickCreate"
          >
            新規追加
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthUtils from '@/utils/AuthUtils'
import axios from 'axios';
import Swal from 'sweetalert2';

// 作物登録ダイアログ
export default {
  name: "CropDialog",
  components: {
  },
  mounted() {
  },
  data() {
    return {
      showInfo: false,
      name: '',
      nameKana: '',
      shippingPeriod: "",
      shipment: "",
      scale: "",
      price: "",
      nameRules: [
        v => !!v || '作物 が 必要です',
        v => (v && v.length <= 50) || '作物 は 50文字以内で入力してください',
      ],
      nameKanaRules: [
        (v) =>
          (v && v.length <= 50) ||
          "フリガナ は 50文字以内で入力してください",
        (v) =>
          (/^[ァ-ンヴー]*$/.test(v)) ||
          "フリガナは全角カタカナで入力してください"
      ],
      valid: false,
      dialog: false
    }
  },
  computed: {
    isUpdate(){
      return this.plantMaster != undefined;
    },
    buttonColor(){
      return this.isUpdate ? "" : "green"
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        axios.post('/api/crops/', {
          'name': this.name,
          'name_kana': this.nameKana,
          'shipping_period': this.shippingPeriod,
          'shipment': this.shipment,
          'scale': this.scale,
          'price': this.price,
          'user': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '作物の登録',
            text: '作物の登録が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
