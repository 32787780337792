<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="buttonColor"
        
        v-bind="attrs"
        v-on="on"
        v-if="!isUpdate"
      >
        {{'アカウント新規登録'}}
      </v-btn>
      <v-icon v-if="isUpdate" @click.stop="dialog = true">mdi-application-edit</v-icon>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">アカウントの登録</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="!isUpdate"
                  v-model="accountName"
                  :counter="50"
                  :rules="accountNameRules"
                  label="アカウント名"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="isUpdate"
                  v-model="accountName"
                  :counter="50"
                  :rules="accountNameRules"
                  label="アカウント名（更新不可）"
                  readonly
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="!isUpdate"
                  v-model="email"
                  :counter="256"
                  :rules="emailRules"
                  label="メールアドレス"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="isUpdate"
                  v-model="email"
                  :counter="256"
                  :rules="emailRules"
                  label="メールアドレス（更新不可）"
                  readonly
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!isUpdate">
                <v-text-field
                  type="password"
                  v-model="password"
                  :counter="20"
                  label="パスワード"
                  :rules="passwordRules"
                  maxlength="20"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="accountType"
                  :items="accountTypeItems"
                  :rules="[v => !!v || 'アカウント区分が必要です']"
                  label="アカウント区分"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="dialog = false"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            
            @click="onClickUpdate"
            v-if="isUpdate"
            :disabled="loading"
          >
            更新
          </v-btn>
          <v-btn
            color="primary"
            
            @click="onClickCreate"
            v-if="!isUpdate"
            :disabled="loading"
          >
            新規追加
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import AuthUtils from '@/utils/AuthUtils.js'

// 栽培情報マスタ登録ダイアログ
export default {
  name: "AcountDialog",
  components: {
  },
  props: {
    accountTypeList: Array(),
    account: Object()
  },
  mounted() {
  },
  data() {
    return {
      accountName: '',
      accountNameRules: [
        v => !!v || 'アカウント名 が 必要です',
        v => (v && v.length <= 50) || 'アカウント名 は 50文字以内で入力してください',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail が 必要です',
        v => /.+@.+\..+/.test(v) || 'E-mail の形式が正しくありません',
      ],
      accountType: '',
      password:"",
      passwordRules: [
        v => !!v || "パスワードは必須です",
        v => (v && v.length > 3) || "パスワードは4文字以上でなければなりません"
      ],
      user_id: '',
      loading: false,
      valid: false,
      dialog: false
    }
  },
  computed: {
    isUpdate(){
      return this.account != undefined;
    },
    buttonColor () {
      return "primary";
    },
    accountTypeItems () {
      if (!this.accountTypeList) return [];
      return this.accountTypeList.map((accountType)=> {
        return {'text':accountType.name, 'value':accountType.id};
      });
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.account) {
          this.accountName = this.account.user.username;
          this.email = this.account.user.email;
          this.accountType = this.account.account_type;
          this.userId = this.account.user.id;
        }
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    showModal () {
      this.dialog = true;
    },
    closeModal () {
      this.$emit("close");
      this.dialog = false;
    },
    onClickUpdate(){
      if (this.validate()) {
        const accountData = {
          account_type: this.accountType,
          user_id: this.userId
        }
        this.loading = true;
        axios.patch(`/api/accounts/${this.account.id}/`, accountData).then(() => {
          this.$emit('reload');
          this.loading = false;
          Swal.fire({
            icon: 'info',
            title: 'アカウントの更新',
            text: 'アカウントの更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          });
          this.formReset();
          this.closeModal();
        }).catch((err)=>{
          this.loading = false;
          if (err.response.data.email) {
            Swal.fire({
              icon: 'warning',
              title: 'アカウント登録エラー',
              text: 'このemailアドレスはすでに登録されています。',
              showConfirmButton:false,
              showCloseButton:true
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: 'サーバーエラーが発生しました',
              showConfirmButton:false,
              showCloseButton:true
            });
          }
        });
      }
    },
    onClickCreate() {
      if (this.validate()) {
        const currentUser = AuthUtils.currentUser(this.$session);
        const accountData = {
          accountName: this.accountName,
          email: this.email,
          accountType: this.accountType,
          password: this.password
        }
        accountData.parent_id = currentUser.id;
        this.loading = true;
        axios.post('/api/accounts/', accountData).then(() => {
        this.loading = false;
          this.$emit('reload');
          Swal.fire({
            icon: 'info',
            title: 'アカウントの作成',
            text: 'アカウントの作成が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          });
          this.formReset();
          this.closeModal();
        }).catch((err)=>{
          this.loading = false;
          if (err.response.data.email) {
            Swal.fire({
              icon: 'warning',
              title: 'アカウント登録エラー',
              text: 'このemailアドレスはすでに登録されています。',
              showConfirmButton:false,
              showCloseButton:true
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: 'サーバーエラーが発生しました',
              showConfirmButton:false,
              showCloseButton:true
            });
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
