<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">{{'栽培情報の登録'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="field.text"
                  label="圃場"
                  readonly
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="plantMasterId"
                  :items="plantMasterItems"
                  :rules="[v => !!v || '栽培グループが必要です']"
                  label="栽培グループ"
                  @change="onChangePlantMaster"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="groupYearId"
                  :items="groupYearItems"
                  :rules="[v => !!v || '年度が必要です']"
                  label="年度"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="plant_date_menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="plantDate"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      label="定植日"
                      :rules="[v => !!v || '定植日が必要です']"
                      @click:clear="plantDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="plantDate"
                    :allowed-dates="isAllowedPlantDates"
                    :show-current="showCurrentDates"
                    @change="onChangePlantDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="harvest_date_menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="harvestDate"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      label="収穫日（収穫予定日）※定植日の入力が先に必要です"
                      :rules="[v => !!v || '収穫日（収穫予定日）が必要です']"
                      @click:clear="harvestDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="harvestDate"
                    :allowed-dates="isAllowedDates"
                    :show-current="showCurrentDates"
                    @change="harvest_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickClose"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            @click="onClickCreate"
          >
            新規追加
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthUtils from '@/utils/AuthUtils'
import axios from 'axios';
import Swal from 'sweetalert2';

// 栽培情報情報登録ダイアログ
export default {
  name: "FieldPlantInfoDialog",
  components: {
  },
  props: {
    plantMasters: Object(),
    field: Object(),
    plantInfo: Object()
  },
  mounted() {
  },
  data() {
    return {
      plantMasterId: '',
      groupYearId: '',
      plant_date_menu: false,
      harvest_date_menu: false,
      plantDate: '',
      harvestDate: '',
      plantMasterItems: [],
      valid: false,
      dialog: false
    }
  },
  computed: {
    buttonColor() {
      return "primary"
    },
    showCurrentDates(){
      if (!this.plantMasterId) return "";
      if (!this.groupYearId) return "";
      const plantMaster = this.plantMasters.filter((plantMaster) => plantMaster.id == this.plantMasterId)[0];
      const groupYear = plantMaster.group_year_list.filter((groupYear) => groupYear.id == this.groupYearId)[0];
      return new Date(groupYear.date).toISOString().substr(0, 10);
    },
    groupYearItems() {
      const groupYearItemList = [{'text':'年度を選択してください', 'value':''}]
      if (!this.plantMasterId) return groupYearItemList;
      const plantMaster = this.plantMasters.filter((plantMaster) => plantMaster.id == this.plantMasterId)[0];
      plantMaster.group_year_list.forEach((groupYear) => groupYearItemList.push({'text': new Date(groupYear.date).toISOString().substr(0, 4), 'value': groupYear.id}));
      return groupYearItemList;
    }
  },
  methods: {
    showModal () {
      this.plantMasterId = '';
      this.plantMasterItems = [{'text':'栽培グループを選択してください', 'value':''}];
      this.plantMasters.forEach((plantMaster) => {
        this.plantMasterItems.push({'text': plantMaster.name, 'value': plantMaster.id});
      });
      this.dialog = true;
    },
    closeModal () {
      this.dialog = false;
    },
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    isAllowedPlantDates(val){
      const dateVal = new Date(val);
      if (!this.plantMasterId) return "";
      if (!this.groupYearId) return "";
      const plantMaster = this.plantMasters.filter((plantMaster) => plantMaster.id == this.plantMasterId)[0];
      const groupYear = plantMaster.group_year_list.filter((groupYear) => groupYear.id == this.groupYearId)[0];

      const startDate = new Date(groupYear.date);
      const maxAllowedDay = new Date(groupYear.date);
      maxAllowedDay.setFullYear(
        maxAllowedDay.getFullYear() + 2
      );
      maxAllowedDay.setDate(
        maxAllowedDay.getDate() - 1
      );
      return (dateVal >= startDate) && (dateVal <= maxAllowedDay);
    },
    isAllowedDates(val){
      const dateVal = new Date(val)
      const startDate = new Date(this.plantDate);
      const maxAllowedDay = new Date(this.plantDate);
      maxAllowedDay.setFullYear(
        maxAllowedDay.getFullYear() + 1
      );
      maxAllowedDay.setDate(
        maxAllowedDay.getDate() - 1
      );
      return (dateVal >= startDate) && (dateVal <= maxAllowedDay);
    },
    onChangePlantDate() {
      this.plant_date_menu = false
      this.harvestDate = '';
    },
    onChangePlantMaster() {
      this.groupYearId = '';
    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        const plantDate = new Date(this.plantDate);
        const harvestDate = new Date(this.harvestDate);
        axios.post('/api/planet/plant_info/', {
          'plant_master_id': this.plantMasterId,
          'field_id': this.field.value,
          'plant_date': plantDate.toISOString(),
          'harvest_date': harvestDate.toISOString(),
          'group_year_id': this.groupYearId,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '栽培情報の登録',
            text: '栽培情報の登録が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickClose() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
