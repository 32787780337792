<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        color="primary"
        
        v-bind="attrs"
        v-on="on"
        @click="reload"
      >
        申請
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        連携申請
      </v-card-title>
      <v-card-subtitle v-if="!isBasic">
        連携機能を利用するには拡張プランが必要です。
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="filterName"
          placeholder="masters ユーザー"
        ></v-text-field>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>ユーザー名</th>
                <th>住所</th>
                <th>申請先を選択</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="masterUser in visibleMasterUsers" :key="masterUser.id" :class="{is_cooperated: masterUser.is_cooperated}">
                <td>{{ masterUser.username }}</td>
                <td>{{ masterUser.address }}</td>
                <td>
                  <v-checkbox
                    :disabled="masterUser.is_cooperated || !isBasic"
                    v-model="masterUser.isSelected"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          @click="dialog = false"
          
        >
          閉じる
        </v-btn>
        <v-btn
          color="primary"
          
          @click="clickRequestButton"
          :disabled="!isSelectedMasterUser"
        >
          申請
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2';

import router from "../../router";
import AuthUtils from '@/utils/AuthUtils.js'

let fuse; // あいまい検索に利用

export default {
  name: 'CooperationUsersDialog',
  mounted() {
    this.reload()
  },
  data() {
    return {
      dialog: false,
      visibleMasterUsers: [],
      masterUsers: [],
      filterName: "",
      isSelectedMasterUser: false
    }
  },
  methods: {
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    reload() {
      this.checkLoggedIn()
      // mastersユーザーの取得
      axios.get('/api/master_users/').then((res) => {
        this.masterUsers = res.data
        .filter(user => {return user.is_farmers_open})
        .map((obj) => {
          obj.isSelected = false
          return obj
        })
        this.visibleMasterUsers = res.data.filter(user => {return user.is_farmers_open})
        fuse = new Fuse(this.masterUsers, {
          keys: [
            'username',
            'first_name',
            'last_name',
          ]
        })
      })
    },
    clickRequestButton() {
      const selectedUserIdList = this.masterUsers.map(obj => {
        if(obj.isSelected === true) {
          return obj.id
        } else {
          return
        }
      }).filter(e => e)
      axios.post('/api/coop_users/', {
        'user_id_list': selectedUserIdList
      }).then(() => {
        this.$emit('reload')
        this.dialog = false
      }).catch((err) => {
        const errorMessage = err.response?.data?.detail ? err.response.data.detail : '予期せぬエラーで申請に失敗しました'
        Swal.fire({
          icon: 'warning',
          title: '申請失敗',
          text: errorMessage,
          showCloseButton: true
        }).then(() => {
          this.reload()
        })
      })
    }
  },
  watch: {
    filterName(val) {
      if (val === '' || val === null) {
        this.visibleMasterUsers = this.masterUsers
      } else {
        this.visibleMasterUsers = fuse.search(val).map(obj => {
          return obj.item
        })
      }
    },
    visibleMasterUsers: {
      handler(array) {
        this.isSelectedMasterUser = false
        array.forEach((obj) => {
          if (obj.isSelected === true) {
            this.isSelectedMasterUser = true
          }
        })
      },
      deep: true
    },
  },
  computed : {
    isBasic() {
      return AuthUtils.isBasic(this.$session);
    }
  }
}
</script>
<style scoped>
.is_cooperated {
  color: #808080;
}
</style>
